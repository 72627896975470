// TODO remove the eslint complexity and fix it
/* eslint-disable complexity */
import SidebarLink from 'Common/components/layout/SidebarLink';
import { roles } from 'User/constants/roles';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import MenuIcon from 'Common/components/layout/MenuIcon';
import { SectionContent } from 'Common/components/layout/Sidebar';
import SidebarSectionTitle from 'Common/components/layout/SidebarSectionTitle';

import isAuthorized from 'User/utils/isAuthorized';
import { useDispatch } from 'react-redux';
import { updateCurrentTab } from 'Application/reducers/reduxTabs';
import colors from 'Application/theme/colors';

const animationVariants = {
	expanded: {
		opacity: 1,
		transition: {
			duration: 0.6,
		},
	},
	shrank: {
		opacity: 0,
		transition: {
			duration: 0.6,
		},
	},
};

const EcoligoSidebarLinks = ({ isShrank }) => {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	let pulseV2InvestorsTab = process.env.REACT_APP_DEV_INVESTORS_PULSE_V2;
	let pulseV2CampaignsTab = process.env.REACT_APP_DEV_CAMPAIGNS_PULSE_V2;
	if (process.env.NODE_ENV === 'test') {
		pulseV2InvestorsTab = process.env.REACT_APP_STAGING_INVESTORS_PULSE_V2;
		pulseV2CampaignsTab = process.env.REACT_APP_STAGING_CAMPAIGNS_PULSE_V2;
	} else if (process.env.NODE_ENV === 'production') {
		pulseV2InvestorsTab = process.env.REACT_APP_PROD_INVESTORS_PULSE_V2;
		pulseV2CampaignsTab = process.env.REACT_APP_PROD_CAMPAIGNS_PULSE_V2;
	}

	const handleDashboardLinkClick = tabIndex => {
		dispatch(updateCurrentTab({ tab: 'dashboard', tabIndex }));
	};

	return (
		<>
			<SidebarSectionTitle isShrank={isShrank}>{t('Dashboard')}</SidebarSectionTitle>
			<SectionContent>
				<SidebarLink to="/impact" onClick={() => handleDashboardLinkClick(0)}>
					<MenuIcon icon="Impact" withTooltip={isShrank} tooltipText="Impact" />
					<motion.span
						variants={animationVariants}
						initial={isShrank ? 'shrank' : 'expanded'}
						animate={isShrank ? 'shrank' : 'expanded'}
					>
						{t('Impact')}
					</motion.span>
				</SidebarLink>
				<SidebarLink to="/my-activities" onClick={() => handleDashboardLinkClick(1)}>
					<MenuIcon icon="checkCircleOutline" withTooltip={isShrank} tooltipText="My activities" />
					<motion.span
						variants={animationVariants}
						initial={isShrank ? 'shrank' : 'expanded'}
						animate={isShrank ? 'shrank' : 'expanded'}
					>
						{t('My Activities')}
					</motion.span>
				</SidebarLink>
				<SidebarLink to="/my-team-activities" onClick={() => handleDashboardLinkClick(2)}>
					<MenuIcon icon="peopleOutlineOutlined" withTooltip={isShrank} tooltipText="My Team Projects" />
					<motion.span
						variants={animationVariants}
						initial={isShrank ? 'shrank' : 'expanded'}
						animate={isShrank ? 'shrank' : 'expanded'}
					>
						{t('My Team')}
					</motion.span>
				</SidebarLink>
			</SectionContent>

			<SidebarSectionTitle isShrank={isShrank}>{t('Projects')}</SidebarSectionTitle>
			<SectionContent>
				<SidebarLink to="/projects">
					<MenuIcon icon="ecoOutlined" withTooltip={isShrank} tooltipText="Projects" />
					<motion.span
						variants={animationVariants}
						initial={isShrank ? 'shrank' : 'expanded'}
						animate={isShrank ? 'shrank' : 'expanded'}
					>
						{t('Projects')}
					</motion.span>
				</SidebarLink>
				<SidebarLink to="/entities">
					<MenuIcon icon="entities" withTooltip={isShrank} tooltipText="Entities" />
					<motion.span
						variants={animationVariants}
						initial={isShrank ? 'shrank' : 'expanded'}
						animate={isShrank ? 'shrank' : 'expanded'}
					>
						{t('Entities')}
					</motion.span>
				</SidebarLink>
				<SidebarLink to="/clients" isActive={/clients|unsignedClients/g.test(pathname)}>
					<MenuIcon icon="domain" withTooltip={isShrank} tooltipText="Clients" />
					<motion.span
						variants={animationVariants}
						initial={isShrank ? 'shrank' : 'expanded'}
						animate={isShrank ? 'shrank' : 'expanded'}
					>
						{t('Clients')}
					</motion.span>
				</SidebarLink>
				<SidebarLink to={pulseV2CampaignsTab}>
					<MenuIcon icon="timeline" withTooltip={isShrank} tooltipText="Campaigns" />
					<motion.span
						variants={animationVariants}
						initial={isShrank ? 'shrank' : 'expanded'}
						animate={isShrank ? 'shrank' : 'expanded'}
					>
						{t('Campaigns')}
					</motion.span>
				</SidebarLink>
				<SidebarLink to="/partners">
					<MenuIcon icon="handshake" withTooltip={isShrank} tooltipText="Partners" />
					<motion.span
						variants={animationVariants}
						initial={isShrank ? 'shrank' : 'expanded'}
						animate={isShrank ? 'shrank' : 'expanded'}
					>
						{t('Partners')}
					</motion.span>
				</SidebarLink>
			</SectionContent>

			<SidebarSectionTitle isShrank={isShrank}>{t('Accounting')}</SidebarSectionTitle>
			<SectionContent>
				<SidebarLink to="/invoices">
					<MenuIcon icon="invoice" withTooltip={isShrank} tooltipText="Invoices" />
					<motion.span
						variants={animationVariants}
						initial={isShrank ? 'shrank' : 'expanded'}
						animate={isShrank ? 'shrank' : 'expanded'}
					>
						{t('Invoices')}
					</motion.span>
				</SidebarLink>
			</SectionContent>

			<SidebarSectionTitle
				authorizedRoles={[
					roles.ADMIN,
					roles.FINANCE,
					roles.ASSET_MANAGER,
					roles.MANAGEMENT,
					roles.PM_TECHNICAL,
					roles.SALES,
				]}
				isShrank={isShrank}
			>
				{t('Partners')}
			</SidebarSectionTitle>
			<SectionContent>
				<SidebarLink to="/epc-partners">
					<MenuIcon icon="epc" color={colors.secondary.dark} withTooltip={isShrank} tooltipText="EPC Partners" />
					<motion.span
						variants={animationVariants}
						initial={isShrank ? 'shrank' : 'expanded'}
						animate={isShrank ? 'shrank' : 'expanded'}
					>
						{t('EPC Partners')}
					</motion.span>
				</SidebarLink>
				<SidebarLink to="/inquiries" authorizedRoles={[roles.ADMIN, roles.MANAGEMENT, roles.SALES]}>
					<MenuIcon icon="flashOn" withTooltip={isShrank} tooltipText="Inquiries" />
					<motion.span
						variants={animationVariants}
						initial={isShrank ? 'shrank' : 'expanded'}
						animate={isShrank ? 'shrank' : 'expanded'}
					>
						{t('Inquiries')}
					</motion.span>
				</SidebarLink>
			</SectionContent>
			{isAuthorized([
				roles.ADMIN,
				roles.MANAGEMENT,
				roles.PM_FUNDRAISING,
				roles.ASSET_MARKETING,
				roles.HEAD_OF_ESG,
			]) && (
				<>
					<SidebarSectionTitle isShrank={isShrank}>{t('Investing')}</SidebarSectionTitle>
					<SectionContent>
						<SidebarLink to={pulseV2InvestorsTab}>
							<MenuIcon icon="investors" withTooltip={isShrank} tooltipText="Investors" />
							<motion.span
								variants={animationVariants}
								initial={isShrank ? 'shrank' : 'expanded'}
								animate={isShrank ? 'shrank' : 'expanded'}
							>
								{t('Investors')}
							</motion.span>
						</SidebarLink>
					</SectionContent>
				</>
			)}
			<SidebarSectionTitle
				authorizedRoles={[
					roles.ADMIN,
					roles.FINANCE,
					roles.ASSET_MANAGER,
					roles.MANAGEMENT,
					roles.PM_TECHNICAL,
					roles.SALES,
				]}
				isShrank={isShrank}
			>
				{t('Admin')}
			</SidebarSectionTitle>
			<SectionContent>
				<SidebarLink
					to="/users"
					authorizedRoles={[
						roles.ADMIN,
						roles.FINANCE,
						roles.ASSET_MANAGER,
						roles.MANAGEMENT,
						roles.PM_TECHNICAL,
						roles.SALES,
					]}
					isActive={/users|epc-users/g.test(pathname)}
				>
					<MenuIcon icon="person" withTooltip={isShrank} tooltipText="Users" />
					<motion.span
						variants={animationVariants}
						initial={isShrank ? 'shrank' : 'expanded'}
						animate={isShrank ? 'shrank' : 'expanded'}
					>
						{t('Users')}
					</motion.span>
				</SidebarLink>
				<SidebarLink to="/preferences" isActive={/preferences|pm-workflow/g.test(pathname)}>
					<MenuIcon icon="settings" withTooltip={isShrank} tooltipText="Preferences" />
					<motion.span
						variants={animationVariants}
						initial={isShrank ? 'shrank' : 'expanded'}
						animate={isShrank ? 'shrank' : 'expanded'}
					>
						{t('Preferences')}
					</motion.span>
				</SidebarLink>
				<SidebarLink
					to="/integrations"
					authorizedRoles={[roles.ADMIN, roles.FINANCE, roles.MANAGEMENT, roles.SALES]}
				>
					<MenuIcon
						icon="integrationInstructions"
						withTooltip={isShrank}
						tooltipText="Integrations"
						color={colors.secondary.dark}
					/>
					<motion.span
						variants={animationVariants}
						initial={isShrank ? 'shrank' : 'expanded'}
						animate={isShrank ? 'shrank' : 'expanded'}
					>
						{t('Integrations')}
					</motion.span>
				</SidebarLink>
			</SectionContent>
		</>
	);
};

EcoligoSidebarLinks.propTypes = {
	isShrank: PropTypes.bool.isRequired,
};

export default EcoligoSidebarLinks;
